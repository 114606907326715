import React, { useState } from 'react';

// COMPONENTS
import AppButton from "../../atoms/Button";

// ICONS
import UahCircle from "../../icons/UahCircle";
import TIcon from "../../icons/TIcon";

// styles
import "./index.scss";

export default ({ generalData }) => {
  const {
    title = "",
    currency_icons = [],
    crypto_icons = [],
    crypto_button_title,
    currency_button_title,
  } = generalData;
  const cardList = {
    crypto: crypto_icons,
    uah: currency_icons,
  };

  const [activeTab, setTab] = useState("crypto");

  const onSetCrypto = () => setTab("crypto");
  const onSetUah = () => setTab("uah");

  const checkTabUah = (type) =>
    activeTab === type
      ? "app-button--fill-3 how-to-use-section-head-btns-icon-uah activeTab"
      : "app-button--outline";

  const checkTabCrypto = (type) =>
    activeTab === type
      ? "app-button--fill-3 activeTab"
      : "app-button--outline how-to-use-section-head-btns-icon-crypto";

  const renderCards = ({ description, image, title, url }, i) => (
    <a
      class="how-to-use-section-list-card"
      key={title + i}
      href={url ? url : ""}
      target="_blank"
    >
      <div class="how-to-use-section-list-card-wrap-img">
        <img src={image} />
      </div>
      <h5>{title}</h5>
      <p>{description}</p>
    </a>
  );

  return (
    <div class="how-to-use-section">
      <div class="app__wrapper">
        <div class="home-icons__box" data-aos="fade-up" data-aos-delay="100">
          <div class="how-to-use-section-head">
            <h3>{title}</h3>

            <div class="how-to-use-section-head-btns">
              <AppButton
                prepend={<TIcon />}
                className={`${checkTabCrypto("crypto")} home-main__btn`}
                onClick={onSetCrypto}
              >
                {crypto_button_title}
              </AppButton>
              <AppButton
                onClick={onSetUah}
                prepend={<UahCircle />}
                className={`${checkTabUah("uah")} home-main__btn`}
              >
                {currency_button_title}
              </AppButton>
            </div>
          </div>

          <div class="how-to-use-section-list">
            {cardList[activeTab].map(renderCards)}
          </div>
        </div>
      </div>
    </div>
  )
}
